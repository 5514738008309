<template>
  <div
    class="relative -mt-[73px] flex h-screen min-h-[800px] items-end text-white after:absolute after:h-full after:w-full after:bg-black after:bg-opacity-30 lg:-mt-[115px]"
  >
    <Image
      v-if="data.media.responsiveImage"
      lazyPreload
      class="absolute h-full w-full"
      img-class="w-full h-full object-cover"
      :src="data.media.responsiveImage.srcSet"
      :src-webp="data.media.responsiveImage.webpSrcSet"
      :width="data.media.responsiveImage.width"
      :height="data.media.responsiveImage.height"
      :alt="data.media?.responsiveImage!.alt"
    />
    <Image
      v-else
      lazyPreload
      img-class="w-full h-full object-cover"
      :src="data.media.url"
      :width="data.media.width"
      :height="data.media.height"
      :alt="data.media.alt"
    />

    <div class="container z-2 grid grid-cols-6 gap-4 pb-9 lg:grid-cols-12 lg:gap-8 lg:pb-14">
      <div class="col-span-6 flex flex-col">
        <DecoratedText
          v-if="data.location"
          :text="data.location"
          class="mb-4 text-sm uppercase tracking-widest after:bg-white after:opacity-30"
        />
        <h1 v-if="data.title" class="mb-4 text-5xl lg:mb-10 lg:text-8xl">{{ data.title }}</h1>
        <p v-if="data.subtitle" class="mb-4 lg:order-4 lg:mb-0 lg:max-w-xs lg:text-lg">
          {{ data.subtitle }}
        </p>
        <CallToAction
          v-if="buttonsAvailableForRender"
          class="lg:order-2 lg:mb-40"
          :data="data.buttons"
        />
      </div>
    </div>
    <div class="z-2 hidden lg:absolute lg:right-0 lg:top-1/2 lg:block lg:-translate-y-1/2">
      <div
        class="relative h-60 w-10 bg-primary before:absolute before:-left-[480px] before:top-1/2 before:block before:w-[480px] before:border-b before:opacity-30 before:content-['']"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  data: {
    type: Object,
    default: () => {}
  }
})
const buttonsAvailableForRender = computed(
  () => Array.isArray(props.data.buttons) && props.data.buttons.length
)
const isMounted = useState('heroMounted')

onMounted(() => {
  isMounted.value = true
})

onBeforeUnmount(() => {
  isMounted.value = false
})
</script>
